import {
  QUESTIONARY_STEPS,
  UserAnswersFlow,
  currentUserState,
  getAnswersCallback,
  getUserProfileCallback,
  useBreakpoint,
  userAnswersLoaderState,
  userAnswersState,
  userLoaderState,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  IconArrowRightComponent,
  IconQuestionaryStep,
  IconWarningComponent,
  IconsStyle,
  LBTButton,
  LBTChip,
  LBTImage,
  LBTLabel,
  LBTListItem,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { APP_ROUTES } from '../../Routes';
import AnswerReview from './AnswerReview';

export default function QuestionaryMenu() {
  const { isDesktop } = useBreakpoint();
  const user = useRecoilValue(currentUserState);
  const answers = useRecoilValue(userAnswersState);
  const [selectedFlowId, setSelectedFlowId] = useState<number>(0);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const getAnswers = useRecoilCallback(getAnswersCallback, []);
  const userLoader = useRecoilValue(userLoaderState);
  const currentUser = useRecoilValue(currentUserState);
  const answerLoader = useRecoilValue(userAnswersLoaderState);
  const navigate = useNavigate();
  const isQuestionaryCompleted =
    !userLoader &&
    !answerLoader &&
    answers.flows.length === QUESTIONARY_STEPS &&
    !answers.flows.find(flow => flow.position !== flow.number_of_pages);

  const isStepCompleted = (step: number) => {
    return currentUser.questionary_status?.some(
      questionary => questionary.completed && questionary.step === step,
    );
  };

  useEffect(() => {
    getUserMe();
  }, []);

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Il tuo questionario' },
    { key: 'page_location', value: '/menu/tuo-questionario/' },
  ]);

  useEffect(() => {
    if (user.id) getAnswers({ id: user.id });
  }, [user.id]);

  const selectedFlow = answers.flows.find(
    (flow: UserAnswersFlow) => flow.flow_id === selectedFlowId,
  );
  if (selectedFlow)
    return (
      <AnswerReview
        flow={selectedFlow}
        onClose={() => {
          setSelectedFlowId(0);
          if (user.id) getAnswers({ id: user.id });
        }}
      />
    );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Questionario
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Puoi modificare le risposte o continuare a rispondere se non hai
          ancora finito
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <Box
          component="ul"
          maxWidth="680px"
          width="100%"
          padding={0}
          margin={0}
        >
          {answers.flows
            .slice()
            .sort((a, b) => a.step - b.step)
            .map((flow: UserAnswersFlow) => (
              <LBTListItem
                key={flow.flow_id}
                component="a"
                title={flow.flow_name}
                onClick={() => setSelectedFlowId(flow.flow_id)}
                leftItem={<IconQuestionaryStep step={flow.step} />}
                rightItem={
                  <Stack flexDirection="row" alignItems="center">
                    {flow.step === 3
                      ? flow.completed_pages < 3 && (
                          <LBTChip
                            icon={
                              <IconWarningComponent
                                style={IconsStyle.OUTLINE}
                              />
                            }
                            color="tangerine"
                            sx={{ minWidth: '50px', marginRight: '10px' }}
                          />
                        )
                      : !isStepCompleted(flow.step) && (
                          <LBTChip
                            label={`${Math.round((flow.position * 100) / (flow.number_of_pages ?? 1))}%`}
                            color="lemonCake"
                            sx={{ minWidth: '50px', marginRight: '10px' }}
                          />
                        )}
                    <IconArrowRightComponent />
                  </Stack>
                }
                sx={{ minHeight: '58px' }}
              />
            ))}
        </Box>
        <LBTSpacer spacing={4} />
        {!isQuestionaryCompleted && (
          <LBTButton
            variant="contained"
            fullWidth
            onClick={() => navigate(`/${APP_ROUTES.QUESTIONARY}`)}
            maxWidth="504px"
          >
            Continua a rispondere
          </LBTButton>
        )}
      </Section>

      {isQuestionaryCompleted && (
        <div
          style={{
            width: '100%',
            height: isDesktop ? '514px' : '360px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage:
              'radial-gradient(181.73% 86.53% at 51.79% 90.97%, #59A5FF 29%, rgba(255, 255, 255, 0.05) 100%)',
          }}
        >
          <LBTSpacer spacing={4} />
          <LBTLabel variant="smallCapsBold">Incredibile ma vero</LBTLabel>
          <LBTSpacer spacing={1} />
          <LBTLabel variant="spGroteskSubtitle">
            Hai risposto a tutte le domande
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTImage image="astronaut" />
        </div>
      )}
    </div>
  );
}
